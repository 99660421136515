import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import SearchForm from './../components/searchForm.js';
import ResultTable from './../components/resultTable.js';
import CustomerForm from './../components/customerForm.js';
import 'bootstrap/dist/css/bootstrap.min.css';


class CreateCustomerPage extends React.Component {

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        try {
            // implicite affectation du paramètre id du tableau params dans ma variable id
            const { getApplications } = this.props;
            await getApplications();
        } catch (error) {
            console.log(error);
        }
    }

    render () {
        const {searchCustomers, customers, resetSearch, applications, searchFirms, firms, createCustomerFirm} = this.props;
        return (
            <div className="App">
                <SearchForm id="searchCustomerForm" inputTextId="searchCustomerInput" search={searchCustomers}/>
                <Container>
                    <Row>
                        <Col>
                            <ResultTable columns={['grc_id', 'name', 'mail', 'action']} results={customers} handleRedirection={resetSearch}/>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <CustomerForm applications={applications} firms ={firms} search={searchFirms} createCustomerFirm={createCustomerFirm} />
                        </Col>
                    </Row>
                </Container>
            </div>
    );
  }
}

export default CreateCustomerPage;

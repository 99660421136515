import { getApplications as getApplicationsApi, searchFirms as searchFirmsApi } from '../../api/paygest.js';
import { RECEIVE_APPLICATIONS, SEARCH_FIRMS } from '../constants/application.js';

export function getApplications() {
    return (dispatch, getState) => {
        getApplicationsApi().then(response => {
            dispatch(listApplications(response.data.data.applications));
        });
    }
}


function listApplications(applications) {
    return {
        type: RECEIVE_APPLICATIONS,
        applications
    }
}

export function searchFirms(app_id, inputText) {
    return (dispatch, getState) => {
        searchFirmsApi(app_id, inputText).then(response => {
            dispatch(listFirm(response.data.data.firms));
        });
    }
}

function listFirm(firms) {
    return {
        type: SEARCH_FIRMS,
        firms
    }
}
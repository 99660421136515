import React from 'react';
import PropTypes from 'prop-types';
import SearchInput from './searchInput.js';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import {Link} from 'react-router-dom'

class SearchForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {searchValue: ''};
        /* faire attention à ces binds */
        this.searchCustomerFormSubmit = this.searchCustomerFormSubmit.bind(this);
    }
    
    async executeSearch(inputText) {
        await this.props.search(inputText);
    }
    
    searchCustomerFormSubmit(event) {
        event.preventDefault();
        this.setState({searchValue: event.target.value}, () => {
            try {
                this.executeSearch(this.state.searchValue)
            } catch (error) {
                console.log(error);
            }
        });
    }

    updateSearchValue(event) {
        /* Ce code permet juste de garder la saisie de l'utilisateur intacte dans le champ (pour garder le comportement normal d'un <input>) */
        this.setState({searchValue: event.target.value});
    }

    render() {
        return (
            <div id="search-wrapper">
                <Container>
                    <Row>
                        <Col>
                            <SearchInput
                                id={this.props.inputTextId}
                                prepend={<FontAwesomeIcon icon={faSearch} />}
                                handleChange={this.searchCustomerFormSubmit} 
                                value={this.state.searchValue} 
                                placeholder="Rechercher un client..."/>
                        </Col>
                        <Col>
                        <Link to="/create" >
                            <Button>
                                Créer client
                            </Button>
                        </Link>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

SearchForm.defaultProps = {
    buttonText: 'Rechercher',
    inputTextId: 'searchInput'
};

SearchInput.propTypes = {
    buttonText: PropTypes.string,
    inputTextId: PropTypes.string
};

export default SearchForm;


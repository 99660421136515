import { connect } from 'react-redux';
import SearchCustomerPage from './../pages/searchCustomerPage.js';
import { searchCustomers, resetSearch } from '../redux/actions/customer';

const mapDispatchToProps = dispatch => ({
    searchCustomers: (inputText) => dispatch(searchCustomers(inputText)),
    resetSearch: () => dispatch(resetSearch())
});

const mapStateToProps = state => ({
    customers: state.customer.customers
})
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchCustomerPage);
  
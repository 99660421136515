import React from 'react';
import PropTypes from 'prop-types';
import {FormControl, InputGroup} from 'react-bootstrap';
class SearchInput extends React.Component {

  render() {
    let prepend = '';
    let append = '';
    if (this.props.prepend) {
      prepend = <InputGroup.Prepend>
                  <InputGroup.Text>
                    {this.props.prepend}
                  </InputGroup.Text>
                </InputGroup.Prepend>;
    }
    if (this.props.append) {
      append = <InputGroup.Append>
                <InputGroup.Text>
                  {this.props.append}
                </InputGroup.Text>
              </InputGroup.Append>;
    }
    return (
      <InputGroup>
        { prepend }
        <FormControl 
          id={this.props.id} 
          name={this.props.name} 
          placeholder={this.props.placeholder} 
          value={this.props.value} 
          onChange={this.props.handleChange}
        />
        { append }
      </InputGroup>
    );
  }
};

SearchInput.defaultProps = {
    id: 'searchInput',
    name: 'searchInput',
    placeholder: 'search...',
    prepend: '',
    append: '',
    handleChange: () => {},
    value: ''
};

SearchInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  prepend: PropTypes.string,
  append: PropTypes.string,
  handleChange: PropTypes.func,
  value: PropTypes.string
};

export default SearchInput;


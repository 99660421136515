import React from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col, Form, InputGroup, Button} from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {fr} from 'date-fns/esm/locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
registerLocale("fr", fr);

class AddCreditForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            application_id: '',
            number_credit: '',
            transactionDate: new Date(),
            payDate: new Date(),
            regroupFirm: '',
            discountFirm: '',
            paymentMode: '',
            total: '',
            commercial: '',
            invoice_pdf: '',
            commentaire: '',
            credit_test: false,
            disabled_input: false
        };

        this.handleApplication = this.handleApplication.bind(this);
        this.handleCredit = this.handleCredit.bind(this);
        this.handleRegroupFirm = this.handleRegroupFirm.bind(this);
        this.handleDiscountFirm = this.handleDiscountFirm.bind(this);
        this.setTransactionDate = this.setTransactionDate.bind(this);
        this.setPayDate = this.setPayDate.bind(this);
        this.handleTotal = this.handleTotal.bind(this);
        this.handleCommercial = this.handleCommercial.bind(this);
        this.handlePaymentMode = this.handlePaymentMode.bind(this);
        this.handleCommentaire = this.handleCommentaire.bind(this);
        this.handleCreditTest = this.handleCreditTest.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    handleApplication(event) {
        this.setState({application_id: event.target.value}, () => {
            this.props.setCreditForm(this.state);
        });
    }

    handleRegroupFirm(event) {
        this.setState({regroupFirm: event.target.value}, () => {
            this.props.setCreditForm(this.state);
        });
    }

    handleCredit(event) {
        const re = /^[0-9\b]+$/;
        event.target.value = event.target.value.replace(/,/g, '.');
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({number_credit:event.target.value}, () => {
                this.props.setCreditForm(this.state);
            });
        }
    }

    handleDiscountFirm(event) {
        const re = /^[0-9\b.]+$/;
        event.target.value = event.target.value.replace(/,/g, '.');
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({discountFirm: event.target.value}, () => {
                this.props.setCreditForm(this.state);
            });
        }
    }

    handleTotal(event) {
        const re = /^[0-9\b.]+$/;
        event.target.value = event.target.value.replace(/,/g, '.');
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({total: event.target.value}, () => {
                this.props.setCreditForm(this.state);
            });
        }
    }

    handleCommercial(event) {
        this.setState({commercial: event.target.value}, () => {
            this.props.setCreditForm(this.state);
        });
    }

    handlePaymentMode(event) {
        this.setState({paymentMode: event.target.value}, () => {
            this.props.setCreditForm(this.state);
        });
    }

    handleCommentaire(event) {
        this.setState({commentaire: event.target.value}, () => {
            this.props.setCreditForm(this.state);
        });
    }
    
    setTransactionDate(date) {
        if (date !== null) {
            this.setState({transactionDate: date});
        }
    }

    setPayDate(date) {
        if (date !== null) {
            this.setState({payDate: date});
        }
    }

    handleCreditTest(event) {
        if(event.target.checked == true){
            this.setState({total: 0});
            this.setState({disabled_input: true});
        }else{
            this.setState({disabled_input: false});
        }
        this.setState({credit_test:event.target.checked}, () => {
            this.props.setCreditForm(this.state);
        });
    }

    onDrop(acceptedFiles) {
        this.setState({invoice_pdf: acceptedFiles[0]}, () => {
            this.props.setCreditForm(this.state);
        });
    }

    render () {
        const { applications, customer, country, commerciaux } = this.props;
        
        return (
            <Container fluid>
                <Row className="text-primary" style={{marginBottom:20, fontWeight:500}}>
                    <Col md="6">
                        <FontAwesomeIcon icon={faBuilding} style={{marginRight:8}}/>{customer.name} ({country.name})
                    </Col>
                    <Col>
                        <FontAwesomeIcon icon={faUser} style={{marginRight:8}}/>{customer.mail}
                    </Col>
                </Row>

                <Row>
                    <Form.Group as={Col} md="6" controlId="regroupFirmInput">
                        <Form.Label>Regroupement Cabinet</Form.Label>
                        <Form.Control
                            placeholder="exemple: ATH"
                            value={this.state.regroupFirm}
                            onChange={this.handleRegroupFirm}/>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="discountFirmInput">
                        <Form.Label>Pourcentage Remise</Form.Label>
                        <InputGroup>
                            <Form.Control 
                                placeholder="0-100"
                                value={this.state.discountFirm} 
                                onChange={this.handleDiscountFirm}/>
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    %
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Form.Group>
                </Row>

                <Row>
                    <Form.Group as={Col} md="6" controlId="applicationIdSelect">
                        <Form.Label>Application <span className="star-required">*</span></Form.Label>
                        <Form.Control
                            as="select"
                            value={this.state.application_id}
                            onChange={this.handleApplication}>
                            <option value="" key="null"></option>
                            {
                                applications.map((values, i) => {
                                    return <option key={`header_${i}`} value={values.id}>{values.name}</option>;
                                })
                            }
                        </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="paymentModeSelect">
                        <Form.Label>Mode de paiement <span className="star-required">*</span></Form.Label>
                        <Form.Control
                            as="select"
                            value={this.state.paymentMode}
                            onChange={this.handlePaymentMode}>
                            <option value=""></option>
                            <option value="virement">Virement</option>
                            <option value="carte">Carte Bancaire</option>
                            <option value="sepa">Prélèvement</option>
                            <option value="cheque">Chèque</option>
                        </Form.Control>
                    </Form.Group>
                </Row>

                <Row>
                    <Form.Group as={Col} md="6" controlId="transactionDate">
                        <Form.Label>Date de transaction <span className="star-required">*</span></Form.Label>
                        <DatePicker
                            id="transactionDate"
                            className="form-control"
                            selected={this.state.transactionDate}
                            onChange={this.setTransactionDate}
                            dateFormat="dd/MM/yyyy"
                            locale={fr}/>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="payDate">
                        <Form.Label>Date de règlement <span className="star-required">*</span></Form.Label>
                        <DatePicker
                            id="payDate"
                            className="form-control"
                            selected={this.state.payDate}
                            onChange={this.setPayDate}
                            dateFormat="dd/MM/yyyy"
                            locale={fr}/>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md="6" controlId="numberCreditInput">
                        <Form.Label>Nombre de crédits <span className="star-required">*</span></Form.Label>
                        <Form.Control
                            placeholder="qté créditée au cabinet"
                            value={this.state.number_credit}
                            onChange={this.handleCredit}/>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="totalInput">
                        <Form.Label>Montant HT <span className="star-required">*</span></Form.Label>
                        <InputGroup>
                            <Form.Control
                                placeholder="montant débité"
                                value={this.state.total}
                                onChange={this.handleTotal}
                                disabled={(this.state.disabled_input)? "disabled" : ""}/>
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    &euro;
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md="6" controlId="checkCreditTest">
                        <Form.Label>Crédit(s) d'essai gratuit</Form.Label>
                        <Form.Check
                            checked={this.state.credit_test}
                            onChange={this.handleCreditTest}/>
                    </Form.Group>

                    <Form.Group as={Col} md="6">
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md="6" controlId="commercialSelect">
                        <Form.Label>Apporteur d'affaire <span className="star-required">*</span></Form.Label>
                        <Form.Control
                            as="select"
                            value={this.state.commercial}
                            onChange={this.handleCommercial}>
                            <option value=""></option>
                            {   
                                commerciaux.map(values => (
                                    <option value={values.id} key={values.id}>{values.name}</option>
                                ))
                            }
                        </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="invoice">
                        <Form.Label>Facture</Form.Label>
                        <Dropzone id="invoice" className="form-control" onDrop={this.onDrop}>
                            {({getRootProps, getInputProps, isDragActive, acceptedFiles}) => (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Button variant="primary">{isDragActive ? "Glissez le fichier ici" : 'Cliquez ou glissez le fichier'}</Button>
                                    <ul className="list-group">
                                        {acceptedFiles.length > 0 && acceptedFiles.map(acceptedFile => (
                                            <li key="accepted_file" className="list-group-item list-group-item-success mt-2">
                                                {acceptedFile.name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </Dropzone>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md="12" controlId="comment">
                        <Form.Label>Commentaire</Form.Label>
                        <Form.Control
                            as="textarea"
                            value={this.state.commentaire}
                            onChange={this.handleCommentaire}/>
                    </Form.Group>
                </Row>
            </Container>  
        );
    }

}

AddCreditForm.defaultProps = {
    applications: [],
    setCreditForm: {}
}

AddCreditForm.propTypes = {
    applications: PropTypes.array,
    setCreditForm: PropTypes.func
};

export default AddCreditForm;

import { RECEIVE_APPLICATIONS, SEARCH_FIRMS } from '../constants/application.js';
const initialState = {
    applications: [],
    firms: []
}

export default function data(state = initialState, action) {
    switch (action.type) {
        case RECEIVE_APPLICATIONS:
            return {
                ...state,
                applications: action.applications
            }
        case SEARCH_FIRMS:
            return {
                ...state,
                firms: action.firms
            }
        default:
            return state;
    }
}

import { LIST_HISTORY, RECEIVE_CUSTOMER, SEARCH_CUSTOMERS, LIST_CUSTOMER_FIRMS, RESET_SEARCH, RECEIVE_COUNTRY, DOWNLOAD_INVOICE, RECEIVE_CUSTOMER_FIRM } from '../constants/customer.js';
const initialState = {
    customer: {},
    country: {},
    customers: [],
    history: [],
    customer_firms: [],
    customer_firm: {}

}

export default function data(state = initialState, action) {
    switch (action.type) {
        case RECEIVE_CUSTOMER:
            return {
                ...state,
                customer: action.customer
            }
        case SEARCH_CUSTOMERS:
            return {
                ...state,
                customers: action.customers
            }
        case LIST_HISTORY:
            return {
                ...state,
                history: action.history
            }
        case LIST_CUSTOMER_FIRMS:
            return {
                ...state,
                customer_firms: action.customer_firms
            }
        case RESET_SEARCH:
            return {
                ...state,
                customers: action.customers
            }
        case RECEIVE_COUNTRY:
            return {
                ...state,
                country: action.country
            }
        case DOWNLOAD_INVOICE:
            return {
                ...state,
                invoice: action.content
            }
        case RECEIVE_CUSTOMER_FIRM:
            return {
                ...state,
                customer_firm: action.customer_firm
            }
        default:
            return state;
    }
}

import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { disconnect, getCurrentUser } from '../redux/actions/google.js';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Button } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

export class LoggedInLayoutBase extends Component {
  state = {
    anchorEl: null,
    redirect: false
  };

  constructor(props) {
    super(props);
    this.handleMenu = this.handleMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.disconnect = this.disconnect.bind(this);
  }

  componentDidMount() {
    const { google } = this.props;
    if (!google.email) {
      if (sessionStorage.getItem('token')) {
      } else {
        this.setState({
          redirect: true
        });
      }
    }
  }

  handleMenu(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  disconnect() {
    const { disconnect } = this.props;

    window.gapi.load('auth2', () => {
      window.gapi.auth2.init({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID
      }).then(res => {
        res.signOut().then(() => {
          disconnect();
          this.setState({
            redirect: true
          })
        });
      });
    });
  }
  
  render() {
    const { children } = this.props;
    const { redirect } = this.state;
    
    if (redirect) {
      return <Redirect to={{ pathname: '/' }}/>
    }

    return (
      <div>
        <ToastContainer
         position="top-center" 
         autoClose={4000}
         />
        <header>
          <div id="logo">
            <img src={'/gestonline-transparent-light.png'} alt="Logo" />
            <span className="name">{process.env.REACT_APP_APP_NAME}</span>
          </div>
          <div>
            <span className="utilisateur">{this.props.google.email}</span>
            <Button onClick={this.disconnect} variant="outline-light">
              Se déconnecter
            </Button>
          </div>
        </header>
        <div>
            { children }
        </div>
      </div>
    )
  }
};

LoggedInLayoutBase.propTypes = {
  classes: PropTypes.object.isRequired
}

LoggedInLayoutBase.defaultProps = {
  classes: {}
}

const mapStateToProps = state => ({
  google: state.google,
  userId: null,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getCurrentUser: (token) => dispatch(getCurrentUser(token, ownProps)),
  disconnect: () => dispatch(disconnect())
});

export const LoginLayout = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(LoggedInLayoutBase));

const LoggedInLayoutRoute = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={matchProps => (
      <LoginLayout>
        <Component {...matchProps} />
      </LoginLayout>
    )} />
  )
};

export default LoggedInLayoutRoute;

import React from 'react';
import {Table, Button} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
class ResultTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {creationClicked: false};
    }

    onClickAction(id, index) {
        this.setState({creationClicked: true}, async () => {
            try {
                await this.props.handleCreationCustomer(id, index);
                this.setState({creationClicked: false});
            } catch (error) {
                console.log(error);
            }
        });
    }

    render() {
        if (this.props.results.length > 0) {
            return (
                <Table>
                    <thead>
                        <tr>
                            {
                                this.props.columns.map((nameColumn, i) => {
                                    return <th key={`header_${i}`}>{nameColumn}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.results.map((row, i) => {
                                return <tr key={`body_${i}`}>{
                                    this.props.columns.map((nameColumn, j) => {
                                        let currentCell = `${row[nameColumn]}`;
                                        if (`${nameColumn}` === 'action') {
                                            currentCell = <Link to={`/client/${row.id}`}>
                                                <Button onClick={this.props.handleRedirection}>
                                                    Voir client
                                                </Button>
                                            </Link>
                                        }else if (`${nameColumn}` === 'createCustomerAction') {
                                            currentCell =
                                                <Button onClick={() => this.onClickAction(row.id, i)} disabled={this.state.creationClicked}>
                                                    Créer client
                                                </Button>
                                        }

                                        return <td key={`body_${i}_${j}`}>
                                            {currentCell}
                                        </td>
                                    })
                                }</tr>
                            })
                        }
                    </tbody>
                </Table>
            );
        }
        return '';
    }
}

ResultTable.defaultProps = {
    columns: [],
    results: []
};

ResultTable.propTypes = {
    columns: PropTypes.array,
    results: PropTypes.array
};

export default ResultTable;


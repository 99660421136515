import { connect } from 'react-redux';
import CustomerPage from './../pages/customerPage.js';
import { getCustomer, getHistory, getCustomerFirms, searchCustomers, resetSearch, getCountry, initCustomerData, updateGrcId, downloadInvoice } from '../redux/actions/customer.js';
import { getApplications } from '../redux/actions/application.js';
import { setCreditForm, saveCredit, getCommerciaux } from '../redux/actions/credit_form.js';


const mapDispatchToProps = dispatch => ({
    getCustomer: (id) => dispatch(getCustomer(id)),
    getCustomerFirms: (customer_id) => dispatch(getCustomerFirms(customer_id)),
    getCountry: (id) => dispatch(getCountry(id)),
    getApplications: () => dispatch(getApplications()),
    getCommerciaux: () => dispatch(getCommerciaux()),
    setCreditForm: (state) => dispatch(setCreditForm(state)),
    saveCredit: (customer_id, credit_form, utilisateur_id) => dispatch(saveCredit(customer_id, credit_form, utilisateur_id)),
    getHistory: (customer_id) => dispatch(getHistory(customer_id)),
    searchCustomers: (inputText) => dispatch(searchCustomers(inputText)),
    resetSearch: () => dispatch(resetSearch()),
    updateGrcId: (customer_id, grc_id) => dispatch(updateGrcId(customer_id, grc_id)),
    initCustomerData: (id) => dispatch(initCustomerData(id)),
    downloadInvoice: (id) => dispatch(downloadInvoice(id))
});

const mapStateToProps = state => ({
    customer: state.customer.customer,
    country: state.customer.country,
    applications: state.application.applications,
    history: state.customer.history,
    credit_form: state.credit_form.credit_form,
    customer_firms: state.customer.customer_firms,
    customers: state.customer.customers,
    commerciaux: state.credit_form.commerciaux
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerPage);
  
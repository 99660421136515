import React from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col, Form, InputGroup, Button} from 'react-bootstrap';
import SearchInput from './searchInput.js';
import ResultTable from './../components/resultTable.js';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {fr} from 'date-fns/esm/locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

registerLocale("fr", fr);

class CustomerForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            application_id: '',
            searchEmailValue: ''
        };

        this.handleApplication = this.handleApplication.bind(this);
        this.searchFirmSubmit = this.searchFirmSubmit.bind(this);
        this.linkFirm = this.linkFirm.bind(this);
    }
F
    async handleApplication(event) {
        this.setState({application_id: event.target.value});
        try{
            const { search } = this.props;
            await search(this.state.application_id, '');
        }catch (error) {
            console.log(error);
        }
    }

    async executeSearch(inputText) {
        const { search } = this.props;
        await search(this.state.application_id, inputText);
    }

    searchFirmSubmit(event) {
        this.setState({searchEmailValue: event.target.value}, () => {
            try {
                this.executeSearch(this.state.searchEmailValue);
            } catch (error) {
                console.log(error);
            }
        });

    }

    async linkFirm(firm_id, index){
        try {
            const { firms, createCustomerFirm, search } = this.props;
            const firm = firms[index];
            // prefer to securize the association
            if (firm['id'] === firm_id){
                await createCustomerFirm(this.state.application_id, firm);
                
            }
        } catch (error) {
            console.log(error);
        }   
    }


    render () {
        const { applications, firms } = this.props;

        let searchInput = ''
        if (this.state.application_id != '') {
            searchInput = <Row>
                <Col>
                    <SearchInput
                            id="firmSearchInput"
                            prepend={<FontAwesomeIcon icon={faSearch} />}
                            handleChange={this.searchFirmSubmit} 
                            value={this.state.searchEmailValue} 
                            placeholder="Rechercher par adresse email..."/>
                </Col>
            </Row>
        }

        return (
            <Container fluid>
                <Row className="text-primary" style={{marginBottom:20, fontWeight:500}}>
                    <Col>
                        <h2>Création d'un compte client Paygest <br/>à partir d'une application Gest On Line</h2>
                    </Col>
                </Row>
                <Row className="text-primary" style={{marginBottom:20, fontWeight:500}}>
                    <Col>
                        <Form.Group as={Col} md="6" controlId="applicationIdSelect">
                            <Form.Label>Application <span className="star-required">*</span></Form.Label>
                            <Form.Control
                                as="select"
                                value={this.state.application_id}
                                onChange={this.handleApplication}>
                                <option value="" key="null"></option>
                                {
                                    applications.map((values, i) => {
                                        return <option key={`header_${i}`} value={values.id}>{values.name}</option>;
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                {searchInput}
                <Row className="text-primary" style={{marginBottom:20, fontWeight:500}}>
                    <Col>
                        <ResultTable columns={['id', 'email', 'name', 'createCustomerAction']} results={firms} app_id={this.state.application_id} handleCreationCustomer={this.linkFirm} />
                    </Col>           
                </Row>
            </Container>  
        );
    }

}

CustomerForm.defaultProps = {
    applications: []
}

CustomerForm.propTypes = {
    applications: PropTypes.array
};

export default CustomerForm;

import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_PAYGEST_API_URL
});

export const searchCustomers = (input) => {
    return instance.get('/search', {params: {input}});
}

export const getCustomer = (id) => {
    return instance.get('/customer', {params: {id}});
}

export const getCustomerFirms = (customer_id) => {
    return instance.get('/customer_firms', {params: {customer_id}});
}

export const getHistory = (customer_id) => {
    return instance.get('/history', {params: {customer_id}});
}

export const getApplications = () => {
    return instance.get('/applications');
}

export const getCommerciaux = () => {
    return instance.get('/commerciaux');
}

export const updateGrcId = (customer_id, grc_id) => {
    return instance.post(
        `/customer/${customer_id}/grc-id`,
        {grc_id}
    );
}

export const saveCredit = (customer_id, credit_form, utilisateur_id) => {
    const formData = new FormData();
    if (credit_form.invoice_pdf !== "") {
        formData.append("invoice_pdf", credit_form.invoice_pdf, credit_form.invoice_pdf.name); 
    }
    formData.append("app_id", credit_form.application_id); 
    formData.append("credit", credit_form.number_credit); 
    formData.append("amount", credit_form.total); 
    formData.append("transaction_date", credit_form.transactionDate); 
    formData.append("pay_date", credit_form.payDate); 
    formData.append("payment_mode", credit_form.paymentMode); 
    formData.append("credit_test", credit_form.credit_test);
    formData.append("regroupement", credit_form.regroupFirm); 
    formData.append("discount", credit_form.discountFirm); 
    formData.append("commercial", credit_form.commercial); 
    formData.append("commentaire", credit_form.commentaire);
    formData.append("utilisateur", utilisateur_id);
    return instance.post(`/customer/${customer_id}/add/credit`, formData);
}

export const getCountry = (id) => {
    return instance.get('/country', {params: {id}});
}

export const downloadInvoice = (id) => {
    return instance.get('/download/invoice', {params: {id}});
}

export const login = (id_token) => {
    return instance.post('/login', {id_token});
}

export const user = (token) => {
    return instance.get('/user', {token});
}

export const searchFirms = (app_id, input) => {
    return instance.get('/firms', {params: {app_id: app_id, email:input}});
}

export const createCustomerFirm = (app_id, firm) => {
    return instance.post('/customer_firm', {app_id, firm});
}

export const getDetails = (id) => {
    return instance.get('/purchase', {params: {id}});
}

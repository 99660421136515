import React from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col, Button, Form, InputGroup, Modal } from 'react-bootstrap';
import ModalForm from '../components/modalForm.js';
import AddCreditForm from '../components/addCreditForm.js';
import HistoryTabs from '../components/historyTabs.js';
import SearchForm from './../components/searchForm.js';
import ResultTable from './../components/resultTable.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faBuilding, faUser, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { initCustomerData } from '../redux/actions/customer.js';
import { toast } from 'react-toastify';

class CustomerPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showModalDetails: false,
            grcIdUpdated:false,
            grcId: '',
            purchase: null,
            meta: null
        };
        // Faut bind sinon on pourra pas faire fonctionner correctement les events
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSaveGrcId = this.handleSaveGrcId.bind(this);
        this.addCredit = this.addCredit.bind(this);
        this.refreshHistory = this.refreshHistory.bind(this);
        this.showDetails = this.showDetails.bind(this);
        this.handleCloseModalDetails = this.handleCloseModalDetails.bind(this);
    }

    // A l'affichage de la page, on récupère les données en base comme une action dans un MVC
    async componentDidMount() {
        try {
            // implicite affectation du paramètre id du tableau params dans ma variable id
            const { initCustomerData, getApplications, getCommerciaux } = this.props;
            const { id } = this.props.match.params;
            if (id !== undefined) {
                Promise.all([await initCustomerData(id), await getApplications(), await getCommerciaux()]);
            }
        } catch (error) {
            console.log(error);
        }
    }

    async componentDidUpdate(prevProps) {
        // Utilisation classique (pensez bien à comparer les props) :
        if (this.props.match.params.id !== prevProps.match.params.id) {
            const { initCustomerData, getApplications, getCommerciaux } = this.props;
            Promise.all([await initCustomerData(this.props.match.params.id), await getApplications(), await getCommerciaux()]);
        }
    }

    // For the modal management
    handleShow(event) {
        if (sessionStorage.getItem('readonly')) {
            if (sessionStorage.getItem('readonly') == 'false') {
                this.setState({show:true});
                return true;
            }
        }
        toast.error("Vous n'avez le droit d'ajouter des crédits");
    }
    // For the modal management
    handleClose(event) {
        this.setState({show:false});
    }

    
    // for purchase details modal
    showDetails(purchase, meta) {
        this.setState({showModalDetails:true, purchase:purchase, meta:meta});
    }

    handleCloseModalDetails(event) {
        this.setState({showModalDetails:false});
    }

    handleChangedGrcId = (event) => {
        const grcId = this.props.customer.grc_id ? this.props.customer.grc_id : '';
        this.setState({
            grcIdUpdated:event.target.value !== grcId,
            grcId: event.target.value
        })
    }

    handleCancelGrcId = () => {
        const grcId = this.props.customer.grc_id ? this.props.customer.grc_id : '';
        this.setState({
            grcIdUpdated:false,
            grcId: grcId
        })
    }

    handleSaveGrcId() {
        this.props.updateGrcId(this.props.customer.id, this.state.grcId)
        this.setState({
            grcIdUpdated:false
        })
    }

    async refreshHistory() {
        const { id } = this.props.match.params;
        const { getHistory } = this.props;
        await getHistory(id);
    }
    // adding credit after submit of the form
    addCredit(event) {
        // Add in BDD the number of credit
        const { id } = this.props.match.params;

        // Verify form before send to API to add credit
        if (this.isValid(this.props.credit_form)) {
            const utilisateur_id = sessionStorage.getItem('id');
            this.props.saveCredit(id, this.props.credit_form, utilisateur_id);
            this.setState({show : false}, () => {
                this.refreshHistory();
            });
        } else {
            alert('erreur du formulaire');
        }
    }

    isNull(value) {
        if((value === '') || (value === undefined)) {
            return true;
        }
        return false;
    }
    // check if the add credit form is valid (required fields, format values...)
    isValid(credit_form) {
        // check if required fields are filled
        if (
            this.isNull(credit_form.application_id) || 
            this.isNull(credit_form.number_credit) ||
            this.isNull(credit_form.transactionDate) ||
            this.isNull(credit_form.payDate) ||
            this.isNull(credit_form.commercial) ||
            this.isNull(credit_form.paymentMode) ||
            this.isNull(credit_form.total)
        ) {
            return false;
        }

        // check if number are float or integer
        if (
            isNaN(parseFloat(credit_form.total)) ||
            isNaN(parseInt(credit_form.number_credit))
        ) {
            return false;
        }

        // IF discount is here, need to be a number
        if(
            (credit_form.discountFirm != '') &&
            isNaN(parseFloat(credit_form.discountFirm))
        ){
            return false;
        }

        return true;
    }

    renderDetails() {
        let tableRows = [];
        let meta = null;
        if (this.state.meta != undefined){
            meta = this.state.meta;
        }
        if (this.state.purchase != undefined){
            const purchase = this.state.purchase;
            if (purchase.num) {
                tableRows = [...tableRows, <tr key='online' ><th>Numéro facture</th><td>{purchase.num}</td></tr>];
            }
            if (purchase.date) {
                let transactionDate = new Date(purchase.date);
                tableRows = [...tableRows, <tr key='transaction_date' ><th>Date de transaction</th><td>{transactionDate.toLocaleDateString()}</td></tr>];
            }
            if (meta.pay_date) {
                let payDate = new Date(meta.pay_date);
                tableRows = [...tableRows, <tr key='pay_date' ><th>Date de paiement</th><td>{payDate.toLocaleDateString()}</td></tr>];
            }
            if (meta.payment_mode) {
                tableRows = [...tableRows, <tr key='payment_mode' ><th>Mode de paiement</th><td>{meta.payment_mode}</td></tr>];
            }
            if (meta.credit_test != undefined) {
                let credit_test = meta.credit_test == 'true' ? 'Oui' : 'Non';
                tableRows = [...tableRows, <tr key='credit_test' ><th>Crédit(s) d'essai gratuit</th><td>{credit_test}</td></tr>];
            }
            if (purchase.quantity) {
                tableRows = [...tableRows, <tr key='quantity' ><th>Quantité</th><td>{purchase.quantity} crédit(s)</td></tr>];
            }
            if (purchase.amount) {
                tableRows = [...tableRows, <tr key='amount' ><th>Montant HT</th><td>{purchase.amount} &euro;</td></tr>];
            }
            if (meta.regroupement) {
                tableRows = [...tableRows, <tr key='regroupement' ><th>Regroupement</th><td>{meta.regroupement}</td></tr>];
            }
            if (purchase.discount_percent) {
                tableRows = [...tableRows, <tr key='discount_percent' ><th>Remise</th><td>{purchase.discount_percent+'%'}</td></tr>];
            }
            if (meta.commentaire) {
                tableRows = [...tableRows, <tr key='commentaire' ><th>Commentaire</th><td>{meta.commentaire}</td></tr>];
            }
            if (purchase.online != undefined) {
                let offline = purchase.online == true ? 'Non' : 'Oui';
                tableRows = [...tableRows, <tr key='online' ><th>Paiement offline</th><td>{offline}</td></tr>];
            }
            if (meta.commercial) {
                tableRows = [...tableRows, <tr key='commercial' ><th>Apporteur d'affaire</th><td>{meta.commercial}</td></tr>];
            }
            if (purchase.created_at) {
                let creationDate = new Date(purchase.created_at);
                tableRows = [...tableRows, <tr key='created_at' ><th>Date de saisie</th><td>{creationDate.toLocaleDateString()}</td></tr>];
            }
            if (purchase.name) {
                tableRows = [...tableRows, <tr key='username' ><th>Saisie par</th><td>{purchase.name}</td></tr>];
            }

        }
        return (
            <div>
                <table className="table-bordered w-100 text-center">
                    <tbody>{ tableRows }</tbody>
                </table>
            </div>
        );
    }

    render() {
        const { searchCustomers, customers, customer, country, applications, setCreditForm, history, customer_firms, resetSearch, downloadInvoice, commerciaux } = this.props;
        // create a list of credit by application
        let listCredits = [];
        customer_firms.forEach(element => {
            applications.forEach(app => {
                if (app.id === element.app_id) {
                    listCredits.push({'id':app.id, 'name': app.name, 'credits': element.credit_number});
                }
            });
        });

        let modalAddCredit = <ModalForm  title="Ajouter des crédits"
            show={this.state.show}
            size="lg"
            validateModal={this.addCredit}
            close={this.handleClose}
            confirmText="Ajouter">
            <AddCreditForm applications={applications} commerciaux={commerciaux} setCreditForm={setCreditForm} customer={customer} country={country} />
        </ModalForm>
        
        if (sessionStorage.getItem('readonly')) {
            if (sessionStorage.getItem('readonly') == 'true') {
                modalAddCredit = ''
            }
        }

        return (
            <div className="App">
                <SearchForm id="searchCustomerForm" inputTextId="searchCustomerInput" search={searchCustomers}/>
                <Container>
                    <Row>
                        <Col>
                            <ResultTable columns={['grc_id', 'name', 'mail', 'action']} results={customers} handleRedirection={resetSearch}/>
                        </Col>
                    </Row>
                    <Row style={{paddingTop:30}}>
                        <Col md="8" style={{fontSize:24, fontWeight:500}}>
                            <FontAwesomeIcon icon={faBuilding} style={{marginRight:15}}/>
                            {customer.name}
                            <span style={{marginLeft:15, fontWeight:200, color:"#555"}}>({country.name})</span>
                        </Col>
                        <Form.Group as={Col} controlId="discountFirmInput">
                            <InputGroup style={{maxWidth:250, float:"right"}}>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        N° GRC
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control 
                                    placeholder="aucun"
                                    value={this.state.grcIdUpdated ? this.state.grcId : (this.props.customer.grc_id ? this.props.customer.grc_id : '')} 
                                    onChange={this.handleChangedGrcId}/>
                                <InputGroup.Append style={{display:this.state.grcIdUpdated?'block':'none'}}>
                                    <Button variant="outline-dark" onClick={this.handleCancelGrcId}>
                                        <FontAwesomeIcon icon={faTimes} style={{width:16, margin:0}}/>
                                    </Button>
                                    <Button variant="outline-success" onClick={this.handleSaveGrcId}>
                                        <FontAwesomeIcon icon={faCheck} style={{width:16, margin:0}}/>
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row style={{marginTop:20}}>
                        <Col md="5">
                            <table className="table-bordered w-100 text-center">
                                <thead>
                                    <tr>
                                        <th>Application</th>
                                        <th>Crédits disponibles</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    listCredits.map(app => {
                                        return(
                                            <tr key={app.id} style={{fontWeight:500, fontSize:20}}>
                                                <td>
                                                    <span style={{display:"inline-flex", alignItems:"center"}}>
                                                        <img src={`/application/${app.id}.png`} alt={app.name} style={{height:16, marginRight:10}}/>
                                                        {app.name}
                                                    </span>
                                                </td>
                                                <td>{app.credits}</td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </Col>
                        <Col className="text-right">
                            <FontAwesomeIcon icon={faUser} style={{marginRight:8}}/>
                            {customer.mail}
                            <div style={{marginTop:20}}>
                                <Button variant="success" className="float-right" onClick={this.handleShow}>
                                    <FontAwesomeIcon icon={faPlus}/> Ajouter Crédits
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop:30, paddingTop:30, borderTop:"3px solid #DDD"}}>
                        <Col>
                            <HistoryTabs history={Object.values(history)} apps={Object.keys(history)} customer_firms={customer_firms} refreshHistory={this.refreshHistory} downloadInvoice={downloadInvoice} showDetails={this.showDetails}/>
                        </Col>
                    </Row>
                    {modalAddCredit}
                    <Modal show={this.state.showModalDetails} onHide={this.handleCloseModalDetails} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>Informations supplémentaires</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            { this.renderDetails() }
                        </Modal.Body>
                    </Modal>
                </Container>
            </div>
        );
    }
}

CustomerPage.defaultProps = {
    name: '',
    customer: {}
};

CustomerPage.propTypes = {
    name: PropTypes.string,
    customer: PropTypes.object,
    getCustomer: PropTypes.func,
    getApplications: PropTypes.func,
    updateGrcId: PropTypes.func
}

export default CustomerPage;

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import thunk from 'redux-thunk';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import rootReducer from './redux/reducers';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import CustomerContainer from './containers/customerContainer.js';
import SearchCustomerContainer from './containers/searchCustomerContainer.js';
import CreateCustomerContainer from './containers/createCustomerContainer.js';
import LoggedInLayoutRoute from './layout/LoggedInLayout';
import Login from './containers/loginContainer.js';
import './style.scss';


const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
);

const routing = store => (
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Login}/>
        <LoggedInLayoutRoute path="/client/:id" component={CustomerContainer} />
        <LoggedInLayoutRoute path="/create" component={CreateCustomerContainer}/>
        <LoggedInLayoutRoute path="/search" component={SearchCustomerContainer}/>
      </Switch>
    </Router>
  </Provider>
)


ReactDOM.render(routing(store), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';


const GOOGLE_BUTTON_ID = 'google-sign-in-button';

export class Login extends Component
{
  constructor(props) {
    super(props);

    this.onSuccess = this.onSuccess.bind(this);
  }

  componentDidMount() {
    document.title = 'Connexion';

    window.gapi.load('auth2', () => {
      window.gapi.auth2.init({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID
      }).then(res => {
        window.gapi.signin2.render(
          GOOGLE_BUTTON_ID,
          {
            width: 200,
            height: 50,
            theme: 'dark',
            onsuccess: this.onSuccess,
          },
        );
      });
    })
  }

  onSuccess(googleUser) {
    const { loginGoogle } = this.props;
    loginGoogle(googleUser);
    this.setState({ auth: true });
  }

  render() {
    const { google } = this.props;
    if (google.email) {
      return <Redirect to={{ pathname: '/search' }}/>
    }
    return (
      <div id="full-size-wrapper">
        <img id="logo-gestonline" src={'/Infographie-GOLsolutions.png'} alt="Logo" />
        <div className="card padding-bottom-20">
          <div className="card-body">
            <h1 className="home-logo">{process.env.REACT_APP_APP_NAME}</h1>
            <div id={GOOGLE_BUTTON_ID}/>
          </div>
        </div>
      </div>
    )
  }
}

Login.propTypes = {
  loginGoogle: PropTypes.func.isRequired,
  google: PropTypes.object.isRequired
};

Login.defaultProps = {
  google: {}
}

export default Login;

import { RECEIVE_GOOGLE_USER, REQUEST_GOOGLE_USER, DELETE_GOOGLE_USER } from '../constants/google';

const initialState = {
  email: '',
  picture: '',
  name: '',
  isFetching: false
}

export default function google(state = initialState, action) {
  switch (action.type) {
    case REQUEST_GOOGLE_USER:
      return {
        ...state,
        isFetching: true
      };
    case RECEIVE_GOOGLE_USER:
      return {
        ...state,
        email: action.email,
        picture: action.picture,
        name: action.name,
        isFetching: false
      };
    case DELETE_GOOGLE_USER:
      return {
        ...state,
        email: '',
        picture: '',
        name: ''
      }
    default:
      return state;
  }
}

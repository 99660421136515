import { combineReducers } from 'redux';
import customer from './customer.js';
import application from './application.js';
import credit_form from './credit_form.js';
import google from './google.js';

export default combineReducers({
    customer,
    application,
    credit_form,
    google
});
import { connect } from 'react-redux';
import Login from '../components/login.js';
import { getGoogleUser } from '../redux/actions/google';


const mapDispatchToProps = dispatch => ({
  loginGoogle: googleUser => {
    const id_token = googleUser.getAuthResponse().id_token;
    dispatch(getGoogleUser(id_token))
  }
});

const mapStateToProps = state => ({
  google: state.google
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);

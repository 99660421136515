import { saveCredit as saveCreditApi, getCommerciaux as getCommerciauxApi } from '../../api/paygest.js';
import { SET_CREDIT_FORM, SAVE_CREDIT_FORM, RECEIVE_COMMERCIAUX } from '../constants/credit_form.js';
import { toast } from 'react-toastify';

export function setCreditForm(state) {
    return (dispatch, getState) => {
        dispatch(creditForm(state));
    }
}

function creditForm(credit_form) {
    return {
        type: SET_CREDIT_FORM,
        credit_form
    }
}

export function saveCredit(customer_id, state, utilisateur_id) {
    return (dispatch, getState) => {
        saveCreditApi(customer_id, state, utilisateur_id).then(response => {
            toast.success('Crédits ajoutés')
            dispatch(returnCreditForm(response.data.data.customer_firm));
        });
    }
}

function returnCreditForm(customer_firm) {
    return {
        type: SAVE_CREDIT_FORM,
        customer_firm
    }
}

export function getCommerciaux() {
    return (dispatch, getState) => {
        getCommerciauxApi().then(response => {
            dispatch(listCommerciaux(response.data.data.commerciaux));
        });
    }
}


function listCommerciaux(commerciaux) {
    return {
        type: RECEIVE_COMMERCIAUX,
        commerciaux
    }
}
import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal} from 'react-bootstrap';

class ModalForm extends React.Component {

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.close} size={this.props.size}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.children}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-dark" onClick={this.props.close}>
                        {this.props.cancelText}
                    </Button>
                    <Button variant="primary" onClick={this.props.validateModal}>
                        {this.props.confirmText}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ModalForm.defaultProps = {
    title: '',
    show: false,
    size: '',
    close: {},
    validateModal: {},
    cancelText: 'annuler',
    confirmText: 'valider',
};

ModalForm.propTypes = {
    title: PropTypes.string,
    show: PropTypes.bool,
    size: PropTypes.string,
    validateModal: PropTypes.func,
    close: PropTypes.func,
    cancelText: PropTypes.string,
    confirmText: PropTypes.string,
};

export default ModalForm;


import { connect } from 'react-redux';
import CreateCustomerPage from './../pages/createCustomerPage.js';
import { searchCustomers, resetSearch, createCustomerFirm } from '../redux/actions/customer.js';
import { getApplications, searchFirms } from '../redux/actions/application.js';


const mapDispatchToProps = dispatch => ({
    searchCustomers: (inputText) => dispatch(searchCustomers(inputText)),
    resetSearch: () => dispatch(resetSearch()),
    getApplications: () => dispatch(getApplications()),
    searchFirms: (app_id, inputText) => dispatch(searchFirms(app_id, inputText)),
    createCustomerFirm: (app_id, infos) => dispatch(createCustomerFirm(app_id, infos))
});

const mapStateToProps = state => ({
    customers: state.customer.customers,
    applications: state.application.applications,
    firms: state.application.firms,
    customer_firm: state.customer.customer_firm
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCustomerPage);

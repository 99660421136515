import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo, faDownload, faEllipsisH, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
class HistoryTabs extends React.Component {
    constructor(props) {
        super(props);
        this.downloadInvoice = this.downloadInvoice.bind(this);
        this.showDetails = this.showDetails.bind(this);
    }
  
    renderHeader() {
        return (
            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginBottom:15}}>
                <h4>Historique des transactions</h4>
                <Button variant="outline-primary" className="float-right" size="sm" onClick={this.props.refreshHistory}>
                    <FontAwesomeIcon icon={faRedo} style={{marginRight:5}}/>Actualiser
                </Button>
            </div>
        )
    }

    async downloadInvoice(event){
        await this.props.downloadInvoice(event.target.value);
    }

    showDetails(purchase, meta){
        this.props.showDetails(purchase, meta);
    }
    

    render() {
        if (this.props.apps.length === 0) {
            return (
                <div>
                    {this.renderHeader()}
                    Cet établissement n'a pas encore effectué de transaction
                </div>
            )
        } else {
            return (
                <div style={{marginTop:30}}>
                    {this.renderHeader()}
                    <Tabs id="history_tabs">
                    {
                        this.props.apps.map((appName, i) => {
                            let app_id = null;
                            if (this.props.customer_firms[0] !== undefined) app_id = this.props.customer_firms[0].app_id;
                            return (<Tab title={<span><img src={`/application/${app_id}.png`} alt={appName} />{appName}</span>} eventKey={appName} key={`tab_${i}`}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Type</th>
                                            <th style={{textAlign:"right"}}>Quantité</th>
                                            <th style={{textAlign:"right"}}>Montant HT</th>
                                            <th style={{textAlign:"center"}}>Regroupement</th>
                                            <th style={{textAlign:"center"}}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.props.history[i].map((values, j) => {
                                                app_id = values.app_id;
                                                let tdType;
                                                let tdQuantity;
                                                let tdTotal;
                                                let tdAction;
                                                let tdRegroupement = <td></td>;
                                                if (values.type !== 'purchase') {
                                                    tdType = <td>Consommation</td>;
                                                    tdQuantity = <td className="text-danger text-right">
                                                        {values.quantity} crédits
                                                        <FontAwesomeIcon icon={faArrowDown} className="text-danger" style={{marginLeft:8}}/>
                                                    </td>;
                                                    tdTotal = <td className="text-right"></td>;
                                                    tdAction = <td></td>;
                                                    tdRegroupement = <td></td>;
                                                } else {
                                                    tdType = <td>Achat</td>;
                                                    tdQuantity = <td className="text-success text-right">
                                                        {values.quantity} crédits
                                                        <FontAwesomeIcon icon={faArrowUp} className="text-success" style={{marginLeft:8}}/>
                                                    </td>;
                                                    tdTotal = <td className="text-right">{values.amount} &euro;</td>;
                                                    tdAction = <td style={{textAlign:"center"}}>
                                                        <Button size="sm" variant="outline-primary" onClick={ () => this.showDetails(values, values.meta)}>
                                                            <FontAwesomeIcon icon={faEllipsisH} style={{marginRight:8}}/>
                                                            détails
                                                        </Button>
                                                        &nbsp;&nbsp;
                                                        <Button size="sm" variant="outline-primary" onClick={this.downloadInvoice} value={values.id} >
                                                            <FontAwesomeIcon icon={faDownload} style={{marginRight:8}}/>
                                                            facture
                                                        </Button>
                                                    </td>;
                                                    if (values.meta) {
                                                        if((values.meta['credit_test'] != undefined && values.meta['credit_test'] == 'true')){
                                                            tdType = <td>Essai</td>;
                                                        }
                                                        tdRegroupement = <td>{values.meta['regroupement'] ? values.meta['regroupement'] : ''}{values.discount_percent ? ' ('+values.discount_percent+'% de remise)': ''}</td>;
                                                    }
                                                    // Special case : when credit of Circulariz comes to E-Circu
                                                    if (values.status === 'transfert') {
                                                        tdType = <td>Transfert</td>;
                                                        tdTotal = <td style={{textAlign:"center"}}>{ values.meta['application'] ? values.meta['application'] : ''}</td>;
                                                        tdAction = <td></td>;
                                                    }
                                                }                                    
                                                return (
                                                    <tr key={`tr_${i}_${j}`}>
                                                        <td> {new Intl.DateTimeFormat("fr-FR", {year:"numeric", month: "long", day:"2-digit"}).format(new Date(values.date))}</td>
                                                        {tdType}
                                                        {tdQuantity}
                                                        {tdTotal}
                                                        {tdRegroupement}
                                                        {tdAction}
                                                    </tr>
                                                )
                                            })
                                        }
                                        <tr style={{backgroundColor:"#F7F7F7"}}>
                                            <td colSpan="2">TOTAL</td>
                                            <td className="text-right">
                                                {
                                                    this.props.customer_firms.map((account, k) => {
                                                        if (account.app_id === app_id) {
                                                            return account.credit_number;
                                                        }
                                                        return 0
                                                    })
                                                } 
                                            &nbsp;crédits</td>
                                            <td colSpan="3"></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Tab>);
                        })
                    }
                    </Tabs>
                </div>
            );
        }
    }

}
HistoryTabs.defaultProps = {
    history: [],
    apps: [],
    downloadInvoice: {}
};

HistoryTabs.propTypes = {
    history: PropTypes.array,
    apps: PropTypes.array,
    downloadInvoice: PropTypes.func
}
export default HistoryTabs;
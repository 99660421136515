import { SET_CREDIT_FORM, RECEIVE_COMMERCIAUX } from '../constants/credit_form.js';
const initialState = {
    credit_form: [],
    commerciaux: []
}

export default function data(state = initialState, action) {
    switch (action.type) {
        case SET_CREDIT_FORM:
            return {
                ...state,
                credit_form: action.credit_form
            }
        case RECEIVE_COMMERCIAUX:
            return {
                ...state,
                commerciaux: action.commerciaux
            }
        default:
            return state;
    }
}

import React from 'react';
import SearchForm from './../components/searchForm.js';
import ResultTable from './../components/resultTable.js';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

class SearchCustomerPage extends React.Component {

  render () {
    const { searchCustomers, customers, resetSearch } = this.props;
    return (
      <div className="App">
          <SearchForm id="searchCustomerForm" inputTextId="searchCustomerInput" search={searchCustomers}/>
          <Container>
              <Row>
                  <Col>
                      <ResultTable columns={['grc_id', 'name', 'mail', 'action']} results={customers} handleRedirection={resetSearch}/>
                  </Col>
              </Row>
          </Container>
      </div>
    );
  }
}

export default SearchCustomerPage;

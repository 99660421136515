import {
    getCustomer as getCustomerApi,
    searchCustomers as searchCustomersApi,
    getHistory as getHistoryApi,
    getCustomerFirms as getCustomerFirmsApi,
    getCountry as getCountryApi,
    downloadInvoice as downloadInvoiceApi,
    updateGrcId as updateGrcIdApi,
    createCustomerFirm as createCustomerFirmApi
} from '../../api/paygest.js';
import {
    RECEIVE_CUSTOMER,
    SEARCH_CUSTOMERS,
    LIST_HISTORY,
    LIST_CUSTOMER_FIRMS,
    RESET_SEARCH,
    RECEIVE_COUNTRY,
    DOWNLOAD_INVOICE,
    RECEIVE_CUSTOMER_FIRM
} from '../constants/customer.js';
import fileDownload from 'js-file-download';
import { toast } from 'react-toastify';

/* First call API to get info on customer */
export function getCustomer(id) {
    return (dispatch, getState) => {
        getCustomerApi(id).then(response => {
            dispatch(receiveCustomer(response.data.data.customer));
        });
    }
}

function receiveCustomer(customer) {
    return {
        type: RECEIVE_CUSTOMER,
        customer
    }
}

/* Second call API to get list of customers by mail or name */
export function searchCustomers(inputText) {
    return (dispatch, getState) => {
        searchCustomersApi(inputText).then(response => {
            dispatch(listCustomer(response.data.data.results));
        });
    }
}

function listCustomer(customers) {
    return {
        type: SEARCH_CUSTOMERS,
        customers
    }
}

/* Third call API to get purchases and expenses group by application on a customer */
export function getHistory(customer_id) {
    return (dispatch, getState) => {
        getHistoryApi(customer_id).then(response => {
            dispatch(listHistory(response.data.data.history));
        });
    }
}

function listHistory(history) {
    return {
        type: LIST_HISTORY,
        history
    }
}

/* Fourth call API to get credit of customer in different applications */
export function getCustomerFirms(customer_id) {
    return (dispatch, getState) => {
        getCustomerFirmsApi(customer_id).then(response => {
            dispatch(listCustomerFirm(response.data.data.customer_firms));
        });
    }
}

function listCustomerFirm(customer_firms) {
    return {
        type: LIST_CUSTOMER_FIRMS,
        customer_firms
    }
}


/* First action without call API, just reset array of result customer in search table */
export function resetSearch() {
    return (dispatch, getState) => {
        dispatch(resetListSearch());
    }
}

function resetListSearch() {
    return {
        type: RESET_SEARCH,
        customers: []
    }
}

/* Fifth call API, to get name of country */
export function getCountry(id) {
    return (dispatch, getState) => {
        getCountryApi(id).then(response => {
            dispatch(receiveCountry(response.data.data.country));
        });
    }
}

function receiveCountry(country) {
    return {
        type: RECEIVE_COUNTRY,
        country
    }
}

export function downloadInvoice(id){
    return (dispatch, getState) => {
        downloadInvoiceApi(id).then(response => {
            dispatch(getDownload(response.data));
        });
    }
}

function getDownload(fileObject) {
    if (fileObject.data.invoice != undefined){
        return {
            type: DOWNLOAD_INVOICE,
            attachment : fileDownload(atob(fileObject.data.invoice), fileObject.data.filename)
        }
    }else{
        toast.warning('aucune facture disponible');
        return {
            type: DOWNLOAD_INVOICE,
            attachment : null
        }
    }
}

export function initCustomerData(id) {
    return (dispatch, getState) => {
        getCustomerApi(id).then(response => {
            dispatch(receiveCustomer(response.data.data.customer));
            getCountryApi(response.data.data.customer.country_id).then(response => {
                dispatch(receiveCountry(response.data.data.country));
            });
        });
        
        getHistoryApi(id).then(response => {
            dispatch(listHistory(response.data.data.history));
        });
        getCustomerFirmsApi(id).then(response => {
            dispatch(listCustomerFirm(response.data.data.customer_firms));
        });
    }
}

export function updateGrcId(id, grc_id) {
    return (dispatch, getState) => {
        updateGrcIdApi(id, grc_id).then(response => {
            toast.success('Numero GRC sauvegardé')
            dispatch(receiveCustomer(response.data.data.customer))
        });
    }
}

export function createCustomerFirm(app_id, firm){
    return (dispatch, getState) => {
        createCustomerFirmApi(app_id, firm).then(response => {
            toast.success('Client créé')
            dispatch(receiveCustomerFirm(response.data.data.customer_firm))
        }).catch(error => toast.error('Le client a déjà un compte associé'));
    }
}

function receiveCustomerFirm(customer_firm) {
    return {
        type: RECEIVE_CUSTOMER_FIRM,
        customer_firm
    }
}